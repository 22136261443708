<template>
  <b-modal
    id="equipment-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="closeModal"
  >
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="equipment?.id">Editar equipamento</span>
        <span v-else>Adicionar equipamento</span>
      </div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>
    <div class="around-content">
      <validation-observer v-slot="{ invalid }">
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <validation-provider name="name" rules="required">
                <label for="name">Nome do equipamento</label>
                <b-input
                  id="name"
                  autocomplete="off"
                  placeholder="Descrever"
                  v-model="form.name"
                />
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group>
              <validation-provider name="type" rules="required">
                <label for="type">Tipo de equipamento</label>
                <multiselect
                  class="with-border"
                  placeholder="Selecionar"
                  :allow-empty="false"
                  :show-labels="false"
                  v-model="form.type"
                  id="type"
                  @input="changeType"
                  :options="['Fixo', 'Rotativo']"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                </multiselect>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="form.type === 'Fixo'">
          <b-col cols="12">
            <validation-provider name="type" rules="required">
              <b-form-group>
                <label for="type">Sala do equipamento</label>
                <multiselect
                  :value="roomSelected"
                  class="with-border"
                  placeholder="Selecionar"
                  label="name"
                  :allow-empty="false"
                  :show-labels="false"
                  @select="value => (form.room_id = value.id)"
                  id="type"
                  :options="rooms"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                </multiselect>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <div class="wrapper-button">
          <b-button
            v-if="equipment?.id"
            :disabled="invalid"
            variant="primary"
            size="lg"
            @click="updateEquipment"
          >
            Atualizar
          </b-button>
          <b-button
            v-else
            variant="primary"
            :disabled="invalid"
            size="lg"
            @click="createEquipment"
          >
            Salvar
          </b-button>
        </div>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'EquipmentModal',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  props: {
    equipment: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    clinic: getCurrentClinic(),
    form: {
      name: null,
      type: null,
      room_id: null
    },
    rooms: []
  }),
  computed: {
    roomSelected() {
      return this.rooms.find(room => room.id === this.form.room_id)
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('equipment-modal')
      this.clearData()
      this.$emit('closeModal')
    },
    clearData() {
      Object.keys(this.form).forEach(key => {
        this.form[key] = null
      })
      this.rooms = []
    },
    async changeType() {
      if (this.form.type === 'Fixo') {
        await this.getAllClinicRooms()
        return
      }
      this.form.room_id = null
      this.rooms = []
    },
    async createEquipment() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.createEquipment({
          ...this.form,
          clinic_id: this.clinic.id
        })
        this.$set(data, 'room', this.roomSelected)
        this.$emit('createdEquipment', data)
        this.closeModal()
        this.$toast.success('Equipamento criado com sucesso')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async updateEquipment() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.updateEquipment(
          this.equipment.id,
          this.form
        )
        if (data.type === 'Fixo') {
          const room = this.rooms.find(room => room.id === data.room_id)
          this.$set(data, 'room', room)
        }

        this.$emit('updatedEquipment', data)
        this.closeModal()
        this.$toast.success('Equipamento atualizado com sucesso')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getAllClinicRooms() {
      try {
        const { data } = await this.api.getAllClinicRooms(this.clinic.id)
        this.rooms = data
      } catch (error) {
        this.$toast.error(error.message)
      }
    }
  },
  watch: {
    equipment: {
      async handler(value) {
        const isLoading = this.$loading.show()
        if (value?.id) {
          this.form = {
            ...value
          }
          if (value.type === 'Fixo') {
            await this.getAllClinicRooms()
          }
        }
        isLoading.hide()
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
#equipment-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
